import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import cla from '../assets/images/cla-persona.png'


const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Enterprise Application Design in Audiovisual | Mike Weisert | mikew.design</title>
            <meta name="description" content="Enterprise System Design in the Audiovisual Industry" />
        </Helmet>

        <div id="main" className="alt">
            <section id="banner" className="major av">
                    <div className="inner">
                    <div className="content">
                        <header className="major">
                            
                            <h1>Enterprise Application Design in Audiovisual</h1>
                            
                            <div className="row">
                                <div className="12u 12u$(small)">Creating an enterprise application for managing sales and service for over $200m in annual business for <a href="https://whitlock.com" target="_blank">Whitlock.</a>
                                <br/>
                                <div className="empDates">2006 - 2012</div>
                                </div>
                            </div>  
                        </header>
                    </div>
                </div>
            </section>
            <section id="glance">
            <div className="inner">
                    <div className="content">
                    <h2>Overview</h2> 
                        <div className="grid-wrapper">
                        
                            <div className="col-8">
                                <h3>Achievements</h3>
                                <ul>
                                    <li>Worked in multiple roles in the company, promoted to the role of Director of Web Applications with a direct developer report</li>
                                    <li>Created a custom CRM system which unified sales data with financials and service data to create a holistic dashboard of the company</li>
                                    <li>Authored multiple reports for leadership that provided up to date insights to steer the company</li>
                                    <li>Designer of all web properties including the company website (2011), intranet and custom web apps</li>
                                    <li>Created many processes critical to business functions, including quoting, reporting and sales activities</li>
                                </ul>
                            </div>
                        
                            <div className="col-4" id="quick">
                                <h3>About Whitlock</h3>
                                <ul>
                                    <li>Whitlock is a privately held audio-visual integrator based in Virginia</li>
                                    <li>Whitlock is the second-largest integrator in the space with sales in excess of $200m annually</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
            </section>
            <section id="three">
                <div className="inner">
                    <div className="content">
                        <h3>The Story</h3>
                            <p>
                            I originally joined Whitlock in the role as an inside sales representative at Microsoft in Redmond, WA.  In this role I was able to help Whitlock establish their footprint in the Pacific Northwest by opening their current office location.
                            </p>
                            <p>
                            Once established in the company, I was promoted into a role managing the company intranet, as well as doing sales & marketing support.  The VP of Sales at the time approached me about consolidating desperate Excel documents across the company into a cohesive web app… all without any budget.
                            </p>
                            <p>
                            Knowing the requirements from the VP, I self-taught myself ColdFusion (the language of our intranet app) and MS SQL Server.  I interviewed and tested designs with sales individuals across the organization to ensure my designs were addressing their needs.  Using an office-by-office approach, I was able to import and train each office on the new CMS with complete user adoption across the company within a year.
                            </p>
                            <p>
                            With a system for tracking sales in place, I expanded it's capabilities to better serve the company's need to for data.  I was able to hook into the existing accounting software (SouthWare) and service software to provide valuable insights to all employees.  Based on these successes I added to my team with an additional developer who worked under my direction.     
                            </p>
                        <h3>Successes</h3>
                            <ul>
                                <li>Built, design and maintained the company intranet.  Intranet included a custom built pricing lookup tool to increase efficiency while pricing AV equipment.</li>
                                <li>Created multiple reports which optimized business reporting and reduced business-critical information timing from weekly updates to hourly.</li>
                                <li>Designed and built a CRM system to manage $200m+ of business annually.</li>
                                <li>Streamlined quoting and bid templates into a macro-driven workflow which leveraged server-based materials and pricing.</li>


                            </ul>
                    </div>
                </div>
            
            </section>
            <section id="cta">
                <div className="inner">
                    <ul className="actions fit">
                        <li><Link to="/professional-services" className="button fit">Back</Link></li>
                        <li><Link to="/fleetmanagement" className="button fit special">Next</Link></li>
                    </ul>
                </div>        
            </section>
        </div>

    </Layout>
)

export default Generic